import { Component } from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';

import { setHeaderFooterVisibility } from 'actions/headerfooter';
import { fetchSymphonyPreviewInfo } from 'actions/landing/landingPageInfo';
import LandingSlot from 'containers/LandingSlot';

const symphonySlotParam = 'slot_center-1';

export class SymphonyPreview extends Component {
  static fetchDataOnServer(store, location) {
    const { [symphonySlotParam]: slot } = parse(location.search);
    if (slot) {
      return Promise.all([store.dispatch(fetchSymphonyPreviewInfo(slot)), store.dispatch(setHeaderFooterVisibility(false))]);
    }
  }

  componentDidMount() {
    const {
      location: { search },
      landingPage: { pageInfo },
      fetchSymphonyPreviewInfo
    } = this.props;
    if (!pageInfo?.componentName) {
      const { [symphonySlotParam]: slot } = parse(search);
      fetchSymphonyPreviewInfo(slot);
    }
  }

  render() {
    const {
      landingPage: { pageInfo }
    } = this.props;
    return pageInfo?.componentName ? <LandingSlot data={pageInfo} /> : <div>There is a problem with this component preview.</div>;
  }
}

function mapStateToProps(state) {
  return {
    landingPage: state.landingPage,
    location: state.router.location
  };
}

export default connect(mapStateToProps, { fetchSymphonyPreviewInfo })(SymphonyPreview);
